$(document).ready(function () {

	/* Remove hover events for touch devices */

	function watchForHover() {
		// lastTouchTime is used for ignoring emulated mousemove events
		let lastTouchTime = 0

		function enableHover() {
			if (new Date() - lastTouchTime < 500) return
			document.body.classList.add('hasHover')
		}

		function disableHover() {
			document.body.classList.remove('hasHover')
		}

		function updateLastTouchTime() {
			lastTouchTime = new Date()
		}

		document.addEventListener('touchstart', updateLastTouchTime, true)
		document.addEventListener('touchstart', disableHover, true)
		document.addEventListener('mousemove', enableHover, true)

		enableHover()
	}

	watchForHover()


	/* Open external links in new tab */

	$('a[href^="http"]').attr({target: "_blank", rel: "noopener noreferrer"});
	$('a[href^="//"]').attr({target: "_blank", rel: "noopener noreferrer"});
	$('a[href^="' + window.location.origin + '"]').attr("target", "_self");


	/* Landing page gallery */

	var $nextSlideshow = $(".slider").parent().find(".nav--next");
	var $prevSlideshow = $(".slider").parent().find(".nav--prev");

	var $carousel = $('.slider').flickity({
		fade: true,
		lazyLoad: true,
		wrapAround: true,
		prevNextButtons: false,
		pageDots: false
	});

	$($nextSlideshow).on('click', function () {
		$carousel.flickity('next');
	});
	$($prevSlideshow).on('click', function () {
		$carousel.flickity('previous');
	});


	/* Index detail gallery */

	var $nextIndexSlideshow = $(".index__item__details").parent().find(".nav--next");
	var $prevIndexSlideshow = $(".index__item__details").parent().find(".nav--prev");

	var $indexdetails = $('.index__item__details').flickity({
		fade: true,
		lazyLoad: true,
		wrapAround: false,
		prevNextButtons: false,
		pageDots: false,
		resize: false
	});

	$($nextIndexSlideshow).on('click', function () {
		$indexdetails.flickity('next');
	});
	$($prevIndexSlideshow).on('click', function () {
		$indexdetails.flickity('previous');
	});



	/* Delay Work image loading to prevent flashes */

	setTimeout(function () {
		$('.item__image').each(function () {
			$(this).css('opacity', "1");
		});
	}, 50);


	/* Change opacity on mouse movement */

	var $win = $(window),
		w = 0,
		h = 0,
		opacityContent = 0,
		opacityImg = 1,
		getWidth = function () {
			w = $win.width();
			h = $win.height();
		};

	$win.resize(getWidth).mousemove(function (e) {

		// var winWidth = $(window).width();
		if ($(window).width() >= 800) {
			opacityContent = ((e.pageY - h / 1.2) / (h / 1.2)) * -3;
			opacityImg = ((e.pageY - h / 1.5) / (h / 1.5)) * -3;
			$('.hasHover.fade nav, .hasHover .item__info, .hasHover .about__item').css('opacity', opacityContent);
			$('.hasHover .about__img').css('opacity', -opacityImg);
			// $('.fade nav, .item__info, .about__item').css('opacity', opacityContent);
			// $('.about__img').css('opacity', -opacityImg);

		}

	}).resize();


	/* Mobile navigation */

	$(".nav__trigger").click(function () {

		console.log("Menu clicked");

		//Wenn Overlay geschlossen...
		if ($(".nav__overlay").hasClass("overlay--hidden")) {

			//Get info
			var bgColor = $(".is-selected").css("background-color")
			var itemTitle = $(".is-selected").find(".item__title--work").html()
			var itemYear = $(".is-selected").find(".item__year").text().trim()
			var itemType = $(".is-selected").find(".item__type").text().trim()
			var itemCounter = $(".is-selected").find(".item__count").text().trim()

			//Öffne Overlay
			$(".nav__overlay").removeClass("overlay--hidden")
			$(".nav__overlay").addClass("overlay--visible")
			$(".nav__trigger").text("X")
			$(".nav__overlay").css("background-color", bgColor)

			$(".menu").removeClass("menu--hidden")
			$(".menu").addClass("menu--visible")
			$(".overlay__title").html(itemTitle)
			$(".overlay__year").text(itemYear)
			$(".overlay__type").text(itemType)
			$(".overlay__count").text(itemCounter)
			$("body").css("overflow", "hidden");

			//Wenn Overlay schon geöffnet ist...
		} else {

			//Schließe Overlay
			$(".nav__overlay").removeClass("overlay--visible")
			$(".nav__overlay").addClass("overlay--hidden")
			$(".nav__trigger").text("Info")
			$(".menu").removeClass("menu--visible")
			$(".menu").addClass("menu--hidden")
			$("body").css("overflow", "scroll");
		}
	})

	$(".nav__overlay").click(function () {
		$(".nav__overlay").removeClass("overlay--visible")
		$(".nav__overlay").addClass("overlay--hidden")
		$(".nav__trigger").text("Info")
		$(".menu").removeClass("menu--visible")
		$(".menu").addClass("menu--hidden")
		$("body").css("overflow", "scroll");
	})


	/* Index: Numbering */

	$(".index__liste__counter").each(function (i) {
		$(this).html(i + 1);
	});


	/* Index: Hover color */

	$(".index__item .item__img").mouseenter(function () {
		var item = $(this).parent();
		var itemColor = item.attr('data-color');
		var itemType = item.attr('data-type');
		var itemCounter = item.parent().find(".index__liste__counter");
		$(".index__color-bg").css("background-color", itemColor);
		// $(".nav__bg--index").removeClass("bg--white").addClass("bg--transparent")
		$(".index__item").not(item).removeClass('visible')
		$(".index__item").not(item).addClass('hidden')
		$(".index__liste__counter").not(itemCounter).removeClass('visible').addClass('hidden')
		$('.index__filter').find("." + itemType).addClass('filter-hover-visible')
		$('.filter__item').not("." + itemType).addClass('filter-hover-hidden')
		$(item).find(".item__title, .item__year").css({"display": "block", "z-index": "70"})
		console.log(itemCounter)
	});

	$(".index__item .item__img").mouseleave(function () {
		var item = $(this).parent();
		var itemCounter = item.parent().find(".index__liste__counter");
		$(".index__item").not(item).removeClass('hidden')
		$(".index__item").not(item).addClass('visible')
		$(".index__liste__counter").not(itemCounter).removeClass('hidden').addClass('visible')
		$(".index__color-bg").css("background-color", "transparent");
		// $(".nav__bg--index").addClass("bg--white").removeClass("bg--transparent")
		$('.filter__item.filter-hover-hidden, .filter__item.filter-hover-visible').removeClass('filter-hover-hidden filter-hover-visible')
		$(item).find(".item__title, .item__year").css({"display": "none", "z-index": "10"})
	});


	/* Index: Filter Work */


	$('.filter__item').click(function () {

		//Filter wird auf Klick ausgewählt
		var filterSelected = $(this).attr('data-type');
		$('.filter__item').not("." + filterSelected).addClass("filter-hidden");
		$('.index__item').not("." + filterSelected).find('img').css({'opacity': '0'})
		$('.index__item').not("." + filterSelected).css({'pointer-events': 'none'})
		$("." + filterSelected).find('img').css({'opacity': '1'})

		//Wenn der selbe Filter schon ausgewählt war, lösche Auswahl
		if ($('.filter__item.' + filterSelected).hasClass("selected")) {
			$(this).removeClass("selected");
			$('.filter-hidden').removeClass("filter-hidden");
			$('.index__item').find('img').css({'opacity': '1'})
			$('.index__item').css({'pointer-events': 'initial'})
		} else {
			//Wenn der selbe Filter noch nicht ausgewählt ist, switche Auswahl
			$(".selected").removeClass('selected').addClass('filter-hidden');
			$(this).addClass('selected');
			$('.index__item').not("." + filterSelected).find('img').css({'opacity': '0'})
			$('.index__item').not("." + filterSelected).css({'pointer-events': 'none'})
			$('.index__item .' + filterSelected).css({'pointer-events': 'initial'})
			$("." + filterSelected).find('img').css({'opacity': '1'})

		}
	})

	/* Index: Overlay */

	// $(".item__img").bind('touchstart click', function () {

	$(".item__img").click(function () {
		var item = $(this).parent();
		var itemCounter = item.parent().find(".index__liste__counter");
		var parentColor = $(this).parent().attr('data-color');
		var parentType = $(this).parent().attr('data-type');
		var imgTitle = $(this).siblings(".item__title").text().trim();
		var imgTitleRaw = $(this).siblings(".item__title").html();
		var imgYear = $(this).siblings(".item__year").text().trim();
		var imgSrc = $(this).find("img").attr('src');
		console.log(imgTitle, imgTitleRaw, imgYear);

		// $(".index__item").not(item).fadeOut(100);
		// $(".index__item").not(item).css("opacity","0");

		var thisGallery = $(this).closest(".index__liste__item").find('.index__item__details');
		var otherGallery = $(this).closest(".index__liste__item").find('.index__item__details');
		var thisGalleryTitle = $(this).closest(".index__liste__item").find('.item__title');
		var thisGalleryNav = $(this).closest(".index__liste__item").find('.slider__nav');
		var thisGalleryClose = $(this).closest(".index__liste__item").find('.slider__close');
		var thisGalleryCounter = $(this).closest(".index__liste__item").find('.index__liste__counter').text();
		var firstSlide = $(this).closest(".index__liste__item").find('.index__item__detail');
		var closeIcons = $(".slider__close, .nav__trigger");

		thisGallery.delay(200).fadeIn(400);
		thisGalleryClose.fadeIn(400);
		thisGalleryNav.fadeIn(600);
		thisGalleryNav.children().css("opacity", "1");
		thisGalleryTitle.fadeOut(200);
		// $(".index__item").not(item).css("visibility","hidden");
		$(".index__item, .index__liste__counter").removeClass("visible").addClass("hidden");
		$(".index__item").css("visibility","hidden");
		$(".index__liste__counter").css("visibility","hidden");
		$(".overlay__counter").text(thisGalleryCounter);
		$(".nav__bg--index").css("pointer-events", "none")
		$(".nav__bg--index").removeClass("bg--white").addClass("bg--transparent")

		// thisGallery.removeClass("details--hidden").addClass("details--visible");

		// $('.index__liste').removeClass("visible").addClass("hidden");
		// $('.index__overlay').css({'background-color': parentColor});
		// $('.index__overlay').find("img").attr("src", imgSrc);
		// $('.index__overlay').removeClass("img-hide").addClass("img-show");
		// $('.index__overlay').find(".info__title").html(imgTitleRaw);
		// $('.index__overlay').find(".info__year").text(imgYear);
		$('.filter__item').not("." + parentType).addClass("overlay-filter-hidden");
		$('.filter__item.' + parentType).addClass("overlay-filter-visible")
		$(".nav__trigger").hide();
		$("body").css("overflow", "hidden");




		closeIcons.click(function () {
			$indexdetails.flickity('select', 0, false, true);
			thisGallery.fadeOut(200);
			thisGalleryClose.fadeOut(200);
			thisGalleryNav.fadeOut(200);
			// thisGalleryNav.css("display", "none");
			$(".index__item, .index__liste__counter").removeClass("hidden").addClass("visible");
			$(".index__item").delay(400).css("visibility","visible");
			$(".index__liste__counter").delay(400).css("visibility","visible");
			$(".nav__trigger").show();
			$('.filter__item').removeClass("overlay-filter-hidden overlay-filter-visible");
			$("body").css("overflow", "scroll");
			$(".nav__bg--index").removeClass("bg--transparent").addClass("bg--white")
		})


	})

	$(".index__overlay").click(function () {
		// $(".overlay__close").click(function () {
		$('.index__overlay').removeClass("show").addClass("hide");
		$('.index__overlay').css({'background-color': 'transparent'});
		$('.filter__item').removeClass("overlay-filter-hidden overlay-filter-visible")
		$(".nav__trigger").show();
		$('.index__liste').removeClass("hidden").addClass("visible");
		$('.index__overlay').removeClass("img-show").addClass("img-hide");
		// $('.index__overlay').find("img").css("opacity", "0").attr("src", "");
	})



});

